import React from "react";
import "../Styles/Home.scss";
import { Button } from "../Components/Buttons";
import Projects from "../Components/Projects";

const Home = () => {
  return (
    <div className="home">
      {/* Hero Section */}
      <section id="hero" className="hero-section">
        <div className="hero-content">
          <h1>Transforming Ideas into Scalable SaaS Solutions</h1>
          <p>
            Building software that delivers value and unlocks growth. Partner
            with us to shape the future of technology.
          </p>
          <div className="cta-buttons">
            <Button type="primary" href="/products">
              Explore Our Products
            </Button>
            <Button type="secondary" href="/partner">
              Become a Partner
            </Button>
          </div>
        </div>
      </section>

      {/* About Section */}
      <section id="about" className="about-section">
        <h2>Why Us?</h2>
        <p className="intro-text">
          Our vision is to build <strong>scalable SaaS products</strong> that
          bring <strong>long-term value</strong> to businesses. With years of
          expertise, we’re not just service providers—we’re{" "}
          <strong>partners in innovation</strong>.
        </p>
        <div className="reasons-list">
          <div className="reason">
            <h3>Solving Complex Problems</h3>
            <p>
              We specialize in solving <strong>complex challenges</strong> in
              healthcare and finance. We’ve tackled issues like critical bugs
              and streamlined payment integrations that comply with{" "}
              <strong>strict regulations</strong>.
            </p>
          </div>
          <div className="reason">
            <h3>Cutting-Edge Technology</h3>
            <p>
              We use the latest tech—<strong>React, Node.js, AWS</strong>
              —allowing us to deliver modern, fast, and secure solutions.
            </p>
          </div>
          <div className="reason">
            <h3>Client Collaboration</h3>
            <p>
              Frequent <strong>communication</strong> is key. We keep you
              involved every step of the way, adapting to your feedback quickly.
            </p>
          </div>
          <div className="reason">
            <h3>Future-Proof Solutions</h3>
            <p>
              Our solutions are built with <strong>scalability</strong> in mind,
              ensuring your system evolves effortlessly as new technologies
              emerge.
            </p>
          </div>
        </div>
      </section>

      {/* SaaS Product Showcases */}
      <section id="products" className="products-section">
        <h2>Explore Our SaaS Platforms</h2>
        <Projects />
      </section>

      {/* Partnership Invitation */}
      <section className="partner-section">
        <h2>Why Partner with Us?</h2>
        <p>
          Our experience in SaaS development has allowed us to build scalable,
          profitable platforms. Join us as a partner, and let's grow together.
        </p>
        <Button type="primary" href="/partner">
          Learn More About Partnerships
        </Button>
      </section>
    </div>
  );
};

export default Home;
