import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";
//Layout
import Layout from "./Components/Layout";
// import Home from "./Pages/Home";
import Home from "./Pages/Home.page"
import Contact from "./Pages/Contact";
import NotFound from "./Pages/NotFound";
import Mars from "./Pages/Mars";
import PodcastLinks from "./Pages/PodcastLinks/PodcastLinks";
import BlogPost from "./Pages/Blog/BlogPost";
import AboutMe from "./Pages/AboutMe";
import YenesPrivacy from "./Pages/Apps/100Yenes/Privacy";
// import Projects from "./Projects";
import Projects from "./Pages/Projects.page";
import ProjectDetail from "./Pages/ProjectDetail.page";

export default class AppNavigation extends Component {
  render() {
    return (
      <Layout>
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/about" exact component={AboutMe} />
          <Route path="/projects" exact component={Projects} />
          <Route path="/projects/:name" exact component={ProjectDetail} />
          <Route path="/prices" exact component={NotFound} />
          <Route path="/contact" exact component={Contact} />
          <Route path="/mars" exact component={Mars} />
          <Route path="/quedirian" exact component={PodcastLinks} />
          <Route path="/blog/:id" exact component={BlogPost} />
          <Route path="/apps/100yenes/privacy" exact component={YenesPrivacy} />
          <Route component={NotFound}></Route>
        </Switch>
      </Layout>
    );
  }
}
