import React, { lazy, Suspense } from "react";
import { Tag } from "antd";
import { useHistory } from "react-router-dom";

// Lazy load the Image component from Ant Design
const LazyImage = lazy(() => import("antd").then(module => ({ default: module.Image })));

export default function ProjectDisplay({
  title,
  company,
  role,
  technologies,
  images,
  backgroundColor,
  name,
  companyLink
}) {
  const history = useHistory();
  const handleClick = () => {
    history.push(`/projects/${name}`);
  };

  return (
    <div className="project-display" onClick={handleClick}>
      {images && (
        <div className="project-image-container" style={{ backgroundColor }}>
          <Suspense fallback={<div>Loading image...</div>}>
            <LazyImage
              className="image"
              preview={false}
              src={images[0] || ""}
              alt={`${title}-image`}
            
            />
          </Suspense>
        </div>
      )}

      <div className="project-header">
        <h3 className="flex">
          <a href={companyLink} onClick={(e) => e.preventDefault()}>
            {company}
          </a>
          {title}
        </h3>
        <h4>{role}</h4>
        <div>
          {technologies.map(({ name, color }) => (
            <Tag key={name} color={color}>
              {name}
            </Tag>
          ))}
        </div>
      </div>
    </div>
  );
}
