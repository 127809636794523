import React from "react";
import ProjectDisplay from "./ProjectDisplay";
import portfolioData from "../Data/portfolio.json";

export default function Projects() {
  return (
    <section id="profesional-experience" className="work">
      {/* <h1 className="txt-primary">Personal Projects</h1> */}

      <div className="project-container">
        {Object.keys(portfolioData).map((key) => {
          return (
            <ProjectDisplay
              key={portfolioData[key].title || `${key}-project`}
              {...portfolioData[key]}
              name={key}
            ></ProjectDisplay>
          );
        })}
      </div>
    </section>
  );
}
